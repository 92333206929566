import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})

export class ProfileComponent implements OnInit {
  
  user: Object;   // wird in ngOnInit direkt übergeben
  profile_username = '';
  
  yourTimezone : string;
  

  showPasswordModal = false;
  pw1 = '';
  pw2 = '';
  pw3 = '';


  constructor(
    private authService: AuthService,
    private flashMessage: FlashMessagesService,
    private router: Router
  ) { }

  ngOnInit() {
    
    this.yourTimezone = Date().toString();

    // Get User when this initializes
    this.authService.getProfile().subscribe(profile => {
      this.user = profile.user;
      this.profile_username = profile.user.username;
    },
    err => {
      console.log(err);
      return false;
    });
  }  

  // *** Password Ändern ***************************
  selectedPasswordModalOK() {
      const user = { username : this.profile_username,
                     pw1      : this.pw1,
                     pw2      : this.pw2
                   } 

      // Validate Rights
      console.log(this.authService.checkOkRights(5));
      if (!this.authService.checkOkRights(10)) {
        this.showPasswordModal = false;
        this.pw1 = '';
        this.pw2 = '';
        this.pw3 = '';
        this.flashMessage.show('> Keine Rechte zur Modifikation <', {cssClass: 'alert-danger', timeout: 3000});
        return false;
      } 

      this.showPasswordModal = false;

      if (this.pw1 != '' && this.pw2.length > 2 && (this.pw2 == this.pw3)) {
        this.authService.dbModifyPassword(user).subscribe(data => {
          if (data.success) {
            this.flashMessage.show('Passwort wurde geändert', {cssClass: 'alert-success', timeout: 4000}); 
          } else {
            this.flashMessage.show('Passwort NICHT geändert: '+data.msg, {cssClass: 'alert-danger', timeout: 4000}); 
          }
        });
      } else
         this.flashMessage.show('Passwort leer oder zu kurz oder nicht gleich', {cssClass: 'alert-danger', timeout: 4000}); 

      this.pw1 = '';
      this.pw2 = '';
      this.pw3 = '';
  }
  selectedPasswordModalCANCEL() {
      this.showPasswordModal = false;
      this.pw1 = '';
      this.pw2 = '';
      this.pw3 = '';
  }

}

