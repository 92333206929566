import { Injectable } from '@angular/core';

@Injectable()
export class ConfigGlobals {
  
  // http_string   : string = 'http://localhost:8080/'; // local development
  http_string : string = '';                            // Empty String for Production and netcup hosting

  //http_string : string = 'https://cloud.stiehl-informatik.de/'; // lokaler Zugriff auf Stiehl-Informatik Node-Server
  
}

/* möglich im Ziel-Component:

Zenario 1:
export class Component1 {
  constructor(private globals: Globals) {
  }
}

Zenario 2:
export class ConfigGlobals {
    private role: string;
    
    constructor(private globals: Globals) {
      this.role = globals.role;
    }
    
    private changedRole() {
      this.globals.role = this.role;
    }
  }

*/